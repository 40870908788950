














































































































import { computed, defineComponent, reactive, toRefs, watch } from "@vue/composition-api";
import { getConsumptionSitesList } from "@/api/energy-assets/consumption_site";

import { dynamicSort, slugify } from "@/utils";
import { IIssuerDetails } from "@/lib/types/organizations/issuer";
import { IConsumerDetails } from "@/lib/types/organizations/consumer";
import { IRetailerDetails } from "@/lib/types/organizations/retailer";
import { IGridOperatorDetails } from "@/lib/types/organizations/grid_operator";
import { IConsumptionSiteDetails } from "@/lib/types/energy-assets/consumption_site";
import { OrganizationTypes } from "@/lib/types/base";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "ConsumptionSitesList",
  components: {
    ConsumptionSitesListFilter: () => import("@/components/consumption-site/ConsumptionSitesListFilter.vue"),
    ConsumptionSiteCrudForm: () => import("@/components/forms/ConsumptionSiteCrudForm.vue"),
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if ([OrganizationTypes.RETAILER, OrganizationTypes.CONSUMER].includes(vm.$store.state.user.details.organization_type)) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    });
  },
  setup () {
    const { root } = rootInstance();

    const state = reactive({
      consumptionSites: [] as IConsumptionSiteDetails[],
      consumptionSitesLoaded: false,
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
      search: "",
      filterSets: reactive({
        city: [] as string[],
        issuer: [] as string[],
        consumer: [] as string[],
        retailer: [] as string[],
        gridOperator: [] as string[],
      }),
      filters: reactive({
        city: [] as string[],
        issuer: [] as string[],
        consumer: [] as string[],
        retailer: [] as string[],
        gridOperator: [] as string[],
      }),
      openFilter: false,
      createModalVisible: false,
    });

    const roles = computed(() => root.$store.getters["user/roles"]);

    const hasFilters = computed(() => {
      return Object.values(state.filterSets as Record<string, any[]>).some((element: any[]) => {
        return element.length > 1;
      });
    });

    const consumptionSitesListDataFn = async () => {
      state.consumptionSitesLoaded = false;

      await getConsumptionSitesList({
        limit: state.limit,
        offset: state.offset,
      })
        .then(res => {
          if (res.results) {
            state.consumptionSites = res.results.sort(dynamicSort("-created_at"));
          }

          state.consumptionSitesLoaded = true;
        })
        .catch(err => console.error(JSON.stringify(err.body.errors.detail)));
    };

    consumptionSitesListDataFn();

    const filteredList = computed(() => {
      let results = state.consumptionSites
        ? [...state.consumptionSites.sort(dynamicSort("-created_at"))]
        : [];

      if (state.search.length) {
        results = results.filter(res => {
          return (
            slugify(res.name).includes(state.search.toLowerCase()) ||
            slugify(res.blockchain_account_address).includes(slugify(state.search)) ||
            slugify(res.id).includes(slugify(state.search))
          );
        });
      }

      if (state.filters.city.length) {
        results = results.filter(res => {
          return state.filters.city.includes(res.location.city);
        });
      }

      if (state.filters.issuer.length) {
        results = results.filter(res => {
          if (res.issuer as IIssuerDetails) return state.filters.issuer.includes((res.issuer as IIssuerDetails).name);
          else return res.issuer === null;
        });
      }

      if (state.filters.consumer.length) {
        results = results.filter(res => {
          if ((res.consumer as IConsumerDetails)) return state.filters.consumer.includes((res.consumer as IConsumerDetails).name);
          else return res.consumer === null;
        });
      }

      if (state.filters.retailer.length) {
        results = results.filter(res => {
          if ((res.retailer as IRetailerDetails)) return state.filters.retailer.includes((res.retailer as IRetailerDetails).name);
          else return res.retailer === null;
        });
      }

      if (state.filters.gridOperator.length) {
        results = results.filter(res => {
          if ((res.grid_operator as IGridOperatorDetails)) return state.filters.gridOperator.includes((res.grid_operator as IGridOperatorDetails).name);
          else return res.grid_operator === null;
        });
      }

      return results;
    });

    const siteCreated = () => {
      consumptionSitesListDataFn();
      state.createModalVisible = false;
    };

    watch(
      () => state.consumptionSites,
      newVal => {
        if (newVal.length) {
          const citySet = new Set<string>();
          const issuerSet = new Set<string>();
          const consumerSet = new Set<string>();
          const retailerSet = new Set<string>();
          const gridOperatorSet = new Set<string>();

          state.consumptionSites.forEach(site => {
            citySet.add(site.location.city);
            retailerSet.add(site.retailer ? (site.retailer as Record<string, any>).name : "-");
            consumerSet.add(site.consumer ? (site.consumer as Record<string, any>).name : "-");
            issuerSet.add(site.issuer ? (site.issuer as Record<string, any>).name : "-");
            gridOperatorSet.add(site.grid_operator ? (site.grid_operator as Record<string, any>).name : "-");
          });

          state.filterSets.city = [...citySet].sort();
          state.filterSets.issuer = [...issuerSet].sort();
          state.filterSets.consumer = [...consumerSet].sort();
          state.filterSets.retailer = [...retailerSet].sort();
          state.filterSets.gridOperator = [...gridOperatorSet].sort();
        }
      },
      { deep: true }
    );

    return { ...toRefs(state), roles, OrganizationTypes, filteredList, siteCreated, hasFilters };
  },
});
